// import onakoya from "./council-images/Onakoya.JPG";
import kemi from "../../images/Kemi.jpg";
import ogunsola from "./council-images/Prof Ogunsola.jpg";
import laloye from "../../images/Fola Laoye.jpg";
import nelson from "../../images/Prof Nelson Oyesiku.jpg";
// import demo from "../../images/demo.webp";
import adejike from '../../images/adejike.jpg'

const councilData = [
    {
        id: 1,
        img: nelson,
        name: "PROF. NELSON OYESIKU",
        office: "Chairman",
        about: [
            "Prof. Nelson M. Oyesiku is the Van L. Weatherspoon, Jr. Eminent Distinguished Professor & Chair, Department of Neurosurgery and Professor, Medicine (Endocrinology) at the University of North Carolina, Chapel Hill, U.S.A",
            "He has held notable positions in various prestigious capacities, including Member of the Board of Directors of the American Board of Neurological Surgery; Chairman of the Maintenance of Certification Committee; Chairman of the American Board of Neurological Surgery; Member of the Board of Governors of the American College of Surgeons; and the Advisory Council for Neurosurgery of the American College of Surgeons.He is one of the first to use 3D endoscopy in pituitary surgery and is also one of few surgeons in the world to utilize advanced 3-D endoscopic surgery for the resection of pituitary tumors.",
            "He was also a member of the Residency Review Committee of Neurosurgery of the ACGME and a Fellow of the American College of Surgeons.He has held leadership positions in the following organizations: the Congress of Neurological Surgeons; the American Academy of Neurological Surgery; the Georgia Neurosurgical Society; the Society of University Neurosurgeons; and the World Federation of Neurosurgical Societies.",
        ]
    },
    {
        id: 2,
        img: adejike,
        name: "DR. AJIBIKE OYEWUMI",
        office: "Member",
        about: [
            "Dr. Ajibike Oyewumi is a distinguished certified healthcare quality and patient safety specialist, as well as an obstetrician/gynecologist. She is the Founding Partner and Principal Consultant of Kalytera-Q Advisory Services, a firm dedicated to providing strategic guidance and support to healthcare organizations and partners focused on enhancing the quality and safety of patient care.",
            "With extensive experience in quality management, Dr. Oyewumi collaborates with healthcare organizations at various stages of their quality journey, from initial system setup to achieving international accreditation. She also offers coaching, mentoring, and training for healthcare professionals to foster continuous improvement.",
            "Dr. Oyewumi joined Lagoon Hospitals in 2002 as an obstetrician and gynecologist and was appointed the first head of the Quality Unit in 2005. Under her leadership, Lagoon Hospitals became the first hospital in sub-Saharan Africa to achieve Joint Commission International (JCI) accreditation in 2011. From 2012 to 2016, she served as a Quality Improvement Advisor with Health Quality Ontario in Canada, supporting provincial efforts to enhance quality care through various hospital improvement projects.",
            "Returning to Lagoon Hospitals in 2017, Dr. Oyewumi played a pivotal role in the hospital’s successful JCI reaccreditations in 2018 and 2021. She also serves as a Consultant in Healthcare Quality and Patient Safety at the International Finance Corporation, where she has assessed and supported healthcare facilities in several countries across Africa and other continents.",
            'Dr. Oyewumi is a member of the Technical Committee of the Society for Quality in Healthcare in Nigeria. She is an accomplished author, contributing research papers and a chapter on Risk Management in Assisted Reproduction.',
            "With over 35 years of experience in the medical field, Dr. Oyewumi is passionately committed to advancing healthcare systems and building capacity for continuous improvement."
        ]
    },
    // {
    //     id: 2,
    //     img: onakoya,
    //     name: "DR. OLAKUNLE ONAKOYA",
    //     office: "Member",
    //     about:
    //         "Dr. Onakoya is renowned clinical practice juggernaut with major interests in Major Limb Trauma and Joint Replacement Surgery. He is ATLS certified, and a Fellow of the West African College of Surgeons.",
    //     about2:
    //         "He obtained his MBBS from the College of Medicine, University of Lagos(CMUL), with a distinction in Medical Ethics and Jurisprudence.He had his residency training in Orthopaedic Surgery and Traumatology at the National Orthopaedic Hospital Igbobi, during which he also completed rotations in Neurosurgery and Cardio- Thoracic surgery at the University College Hospital, Ibadan(UCH).",
    //     about3:
    //         "He became the Chief Resident of the National Orthopaedic Hospital, and received further specialist training in the South West Thames Regional Health Trust.He subsequently worked as a Specialist Orthopaedic Registrar at the St.Georges Hospital Tooting Broadway, and at several other notable hospitals in Surrey and London.",
    //     about4:
    //         " Dr.Onakoya is a member of the following professional associations: Nigerian Orthopaedic Association(NOA); British Orthopaedic Association(BOA); American Academy of Orthopaedic Surgeons(AAOS); AO Trauma; International Society of Orthopaedic Surgery and Traumatology(SICOT); and the Society for Quality Healthcare in Nigeria(SQHN).",
    // },

    {
        id: 3,
        img: ogunsola,
        name: "PROF. FOLASADE OGUNSOLA",
        office: "Member",
        about: [
            "Professor Ogunsola is a Professor of Medical Microbiology with specialty in disease control, particularly HIV/AIDS. She was a founding member of the Nigerian Society for Infection control in 1998 and is also a member of the Global Infection Prevention and control Network. She has been the Chairman of Infection Control Committee of Lagos University Teaching Hospital (LUTH)",
            "In 2014, she was elected as the first female Provost of the College of Medicine, University of Lagos (CMUL) and in 2017, she was appointed as the Deputy Vice Chancellor (Development Services) of the University of Lagos. In November 2022, she was sworn in as the first female Vice Chancellor of the prestigious University of Lagos (UNILAG).",
        ]
    },
    {
        id: 4,
        img: laloye,
        name: "FOLA LAOYE",
        office: "Member",
        about: [
            "Fola Laoye is the co-Founder and CEO of Iwosan Investments Limited, a Health Investments Holding Company, and owners of Iwosan Lagoon Hospitals. She was previously Director at Investment Fund for Health in Africa (IFHA), a pioneer private equity fund focused on Healthcare in Africa.",
            "She was a founding board member of Hygeia Group Nigeria, founders of Lagoon Hospitals and Hygeia HMO, where she served as CEO (2002-2012) and as Chairperson (2012-2015). She is a Trustee of the Board of the Society for Quality in Healthcare in Nigeria (SQHN) and is also a member of the Board of the Institute of Healthcare Improvement (IHI), USA.",
            "She is Chairperson of the boards of Old Mutual Life Assurance and FSDH Asset Management and is a board member of Retail Supermarkets (Shoprite Nigeria). As part of her philanthropic dispositions, she currently chairs the Elebute-LUTH Welfare Board and co-chairs the Africa Regional Board of the Young Presidents Organization (YPO). She is also a member of Harvard Business School Africa Advisory Board, as well as a trustee of the Harvard Business School Association of Nigeria.",
            "She holds an MBA from Harvard Business School (class of 1999) and a bachelor’s degree in accounting from the University of Lagos, Nigeria. She is a Chartered Accountant with vast training from Ernst & Young, Lagos, and Coopers & Lybrand (now PwC), London. She is a Member of the Institute of Chartered Accountants in England & Wales and the Institute of Chartered Accountants of Nigeria.",
        ]
    },
    {
        id: 5,
        img: kemi,
        name: "DR. KEMI BABAGBEMI",
        office: "Member",
        about: [
            "Dr. Babagbemi is an Associate Professor   of   Clinical   Radiology   and   Vice   Chair   for Diversity,   Equity,   and   Inclusion   at   the   Department   of   Radiology   at   Weill   Cornell Medical College, Cornell University.",
            " She received her medical training and residency at Harvard Medical School. During her time at Weill Medicine Radiology, she served as Faculty Scholar in Health Equity for the Diversity Center for Excellence from 2019 - 2020   and   Assistant   Program   Director  of   the   Radiology   Residency   Program   from 2006 - 2014.",
            "  She is an expert in Women's Imaging, with core specialty in Breast Imaging and Pelvic Ultrasound.Lately, her work has focused on improving diversity and inclusion  in  health  care  settings and  in  understanding and  addressing  health disparities   as   it   relates   to   the   access   to   diagnosis   and   treatment   of   diseases.",
            " In recognition of her sterling efforts, she was recently granted the Jeanne and Herbert Siegel Award for Excellence in Medical Education at Weill Cornell Medical College (thereby   becoming   the   first   radiologist   to   receive   this   highest   honor   from   the medical   school),   and   was   recognized   as   Fellow   of   the   American   College   of Radiology, a feat attained by only the top 15% in the field.",
        ]
    },
]
export default councilData;
