import fola from "../../images/Fola Adeola.jpg";
import laloye from "../../images/Fola Laoye.jpg";
import nelson from "../../images/Prof Nelson Oyesiku.jpg";
import oladapo from "../../images/Dapo Oshinusi.jpg";
// import pitan from "./team-img/Olukayode Pitan.jpg";
// import placeholder from '../../images/placeholder.jpg'
import olasupo from '../../images/olasupo.jpg'

const boardData = [
    {
        id: 1,
        img: fola,
        name: "Fola Adeola, OFR; mni",
        office: "Co-Founder & Chairman",
        about: [
            "Fola Adeola is Chairman of Main One Cable Company Limited, owner-managers of the first open access submarine cable in West Africa. He is also the Chairman of FATEFoundation. He is a member of the Board of Trustees of the International Crises Group.Prior to these, Fola founded Guaranty Trust Bank Plc and served as its first ManagingDirector.",
            " In the public sector, Fola served as the first Chairman of the National Pension Commissionin Nigeria; Member of the National Honours and Awards Committee; Member of the SolidMineral Committee(both federally constituted); Member of Council, Institute ofChartered Accountants of Nigeria; and Member of the Global Advisory Committee onPhilanthropy of the World Economic Forum.He served as a Commissioner on theCommission for Africa, prelude to the 2005 G8 meeting in Gleneagles, UK.Until June 2015, he was the Deputy Chairman of the Victims of Terrorism Support Fund in Nigeria.",
            " Fola was nationally decorated as Officer of the Order of the Federal Republic(OFR) bythe Nigerian President in 2002. He is an alumnus of the National Institute for Policy andStrategic Studies in Kuru, Jos, Nigeria.He is married with six children.",
        ]
    },
    {
        id: 2,
        img: laloye,
        name: "Fola Laoye",
        office: "Co-Founder & CEO",
        about: [
            "Fola Laoye is the co-Founder and CEO of Iwosan Investments Limited, a Health Investments Holding Company, and owners of Iwosan Lagoon Hospitals. She was previously Director at Investment Fund for Health in Africa (IFHA), a pioneer private equity fund focused on Healthcare in Africa.",
            "She was a founding board member of Hygeia Group Nigeria, founders of Lagoon Hospitals and Hygeia HMO, where she served as CEO (2002-2012) and as Chairperson (2012-2015). She is a Trustee of the Board of the Society for Quality in Healthcare in Nigeria (SQHN) and is also a member of the Board of the Institute of Healthcare Improvement (IHI), USA.",
            "She is Chairperson of the boards of Old Mutual Life Assurance and FSDH Asset Management and is a board member of Retail Supermarkets (Shoprite Nigeria). As part of her philanthropic dispositions, she currently chairs the Elebute-LUTH Welfare Board and co-chairs the Africa Regional Board of the Young Presidents Organization (YPO). She is also a member of Harvard Business School Africa Advisory Board, as well as a trustee of the Harvard Business School Association of Nigeria.",
            "She holds an MBA from Harvard Business School (class of 1999) and a bachelor’s degree in accounting from the University of Lagos, Nigeria. She is a Chartered Accountant with vast training from Ernst & Young, Lagos, and Coopers & Lybrand (now PwC), London. She is a Member of the Institute of Chartered Accountants in England & Wales and the Institute of Chartered Accountants of Nigeria.",
        ]
    },
    {
        id: 3,
        img: oladapo,
        name: "Oladapo Oshinusi",
        office: "Co-Founder & Board Member",
        about: [
            "He is the Founder and CEO of Mansfield Energy, a leading provider of oil and gas services in West and South Africa. He was President of Reslink for Africa after working with a multinational oil and gas services company for more than 20 years in Europe, Middle East, and Africa.",
            "He sits as Chairman on the board of several companies and is a member of the Institute of Directors and a certified Independent Director.He was Chairman of the Society of Petroleum Engineers(SPE), Lagos section.He was the Secretary of Petroleum Technology Association of Nigeria(PETAN), an association committed to the development of the Nigerian content with the transfer of technology to Nigerian  companies in the oil and gas sector.He is a member of several professional societies and social clubs.",
            "He is an alumnus of Harvard Business School, USA."
        ]
    },
    {
        id: 4,
        img: nelson,
        name: "Prof. Nelson M. Oyesiku",
        office: "Board Member",
        about: [
            "Prof. Nelson M. Oyesiku is the Van L. Weatherspoon, Jr. Eminent Distinguished Professor & Chair, Department of Neurosurgery and Professor, Medicine (Endocrinology) at the University of North Carolina, Chapel Hill, U.S.A.",
            "He has held notable positions in various prestigious capacities, including Member of the Board of Directors of the American Board of Neurological Surgery; Chairman of the Maintenance of Certification Committee; Chairman of the American Board of Neurological Surgery; Member of the Board of Governors of the American College of Surgeons; and the Advisory Council for Neurosurgery of the American College of Surgeons.He is one of the first to use 3D endoscopy in pituitary surgery and is also one of few surgeons in the world to utilize advanced 3-D endoscopic surgery for the resection of pituitary tumors.",
            "He was also a member of the Residency Review Committee of Neurosurgery of the ACGME and a Fellow of the American College of Surgeons.He has held leadership positions in the following organizations: the Congress of Neurological Surgeons; the American Academy of Neurological Surgery; the Georgia Neurosurgical Society; theSociety of University Neurosurgeons; and the World Federation of Neurosurgical Societies.",
        ]
    },
    // {
    //     id: 5,
    //     img: pitan,
    //     name: "Olukayode Pitan",
    //     office: "Board Member",
    //     about: "",
    // },
    {
        id: 6,
        img: olasupo,
        name: "Olusi Olasupo",
        office: "Board Member",
        about: [
            "Dr. Olasupo Olusi is a distinguished economist and seasoned development finance expert, with a remarkable over two-decade career dedicated to shaping economic policies, fostering fiscal sustainability, and driving private and financial sector development on a global scale. Currently the Managing Director and CEO of the Bank of Industry, Dr. Olusi brings a wealth of expertise honed through his extensive tenure with prestigious institutions such as the World Bank and the International Finance Corporation (IFC).",
            "His illustrious journey commenced at the World Bank, where he significantly contributed to capital markets reform and conducted ground-breaking research on finance and private sector development in the Middle East and North Africa. Over the years, Dr. Olusi has assumed pivotal roles, including as a World Bank Country Economist for many countries, Economic Adviser at the Federal Ministry of Finance in Nigeria, and Senior Private Sector Specialist at the International Finance Corporation (IFC).",
            'Dr. Olusi holds a B.Sc. Economics from Hull University, UK; M.Sc. in International Money, Finance, and Investment at the University of Durham, UK and Ph.D. in Economics, also from the University of Durham. He is a proud member of the Royal Economic Society (United Kingdom) and the American Finance Association (USA).'
        ]
    },
]
export default boardData;
