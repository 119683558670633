import React, { useState, useRef, useEffect } from "react";
import "./team.css";
import boardData from "./data";

export default function Board() {

  const [data, setData] = useState();
  const [showModal, setShowModal] = useState(false);

  const handleClick = (data) => {
    setData(data);
    setShowModal(!showModal);
  };
  let menuRef = useRef();

  useEffect(() => {
    let handler = (e) => {
      if (!menuRef?.current?.contains(e.target)) {
        setShowModal(false);
      }
    };
    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  return (
    <>
      <section
        className="board-carousel"
        id="data-carousel"
      >
        {boardData.map((data) => {
          return (
            <section
              key={data.id}
            >
              <div
                onClick={(e) => handleClick(data)}
                className="staff"
                key={data.id}
              >
                <figure>
                  <img src={data.img} alt={data.name} />
                </figure>
                <div>
                  <h3>{data.name}</h3>
                  <h4>{data.office}</h4>
                  <span></span>
                </div>
              </div>
            </section>
          );
        })}
        {showModal && (
          <div className="modal">
            <div ref={menuRef}>
              <button
                onClick={() => {
                  setShowModal(!showModal);
                }}
              >
                x
              </button>
              <figure>
                <img src={data.img} alt="" />
              </figure>
              <div className="text">
                <h3>{data.name}</h3>
                <h4>{data.office}</h4>
                <span></span>
                {data?.about?.map((about, index) =>
                  <p key={index}>{about}</p>
                )}
              </div>
            </div>
          </div>
        )}
      </section>
    </>
  );
}
